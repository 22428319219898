import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useAccount, useMsal } from '@azure/msal-react';
import React, { createContext } from 'react';
import config from 'environments/environment';
import { logMessage } from 'utils/telemetry-utils';
import { hoursToSeconds } from 'utils/time-utils';
import Environment from 'environments/environment';
import { Role } from 'configs/roles';
// eslint-disable-next-line no-restricted-imports
import { BrowserCacheKeys } from 'utils/browser-cache-utils';
import useActiveAccount from 'components/auth/use-active-account';

//See ReadMe:Notes Auth-Context RenderMode
export enum RenderMode {
    Web,
    Kiosk,
    KioskDebug,
    KioskLanding,
    KioskLandingDebug,
    Display,
    DisplayDebug,
}
const kioskLandingPaths: string[] = ['facilities-kiosk-landing', 'facilities-kiosk-provision'];
const kioskPaths: string[] = ['facilities-kiosk'];
const displayPaths: string[] = ['facilities-display'];
const webModeEquivalents: RenderMode[] = [
    RenderMode.Web,
    RenderMode.KioskDebug,
    RenderMode.KioskLandingDebug,
    RenderMode.DisplayDebug,
];

const facilitiesKioskMicrosoftGraphTokenInfo = {
    scope: 'user.read.all',
    key: 'MICROSOFT_GRAPH_AAD_TOKEN',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isKioskAppPresent = (window as any)?.chrome?.webview?.hostObjects !== undefined;

/**
 * Global values for determining aadToken values when site is running in a WebView2 system.
 */
const webViewTokenGlobals = {
    //Regex is looking for api://<clientId>/<otherstuff don't care>
    apiRegex: /api:\/\/(.+)\/(.*)/,
    maxLoopBeforeFail: 100,
    sleepTimeMs: 10,
    badValue: '',
};

// null! needed for creating context without specifying default values required by typescript
export const AuthContext = createContext<IAuthContext>(null!);

function AuthContextProvider(props: IAuthProviderProps): JSX.Element {
    const defaultScope = Environment.aadConfig.scopes.default;
    const { instance, inProgress } = useMsal();
    const activeAccount = useActiveAccount();
    const account = useAccount(activeAccount);

    function isInRole(roleToCheck: Role): boolean {
        const userProfile = getUserProfile();

        if (!userProfile || !userProfile.roles) {
            return false;
        }

        const isMsalRolesEmpty: boolean = userProfile.roles.length === 0;
        const isRoleToCheckEmpty: boolean = roleToCheck.trim() === '';
        const hasNoRoleToCheck: boolean = isMsalRolesEmpty || isRoleToCheckEmpty;

        if (hasNoRoleToCheck) {
            return false;
        }

        const roleToCheckLower = roleToCheck.toLocaleLowerCase().trim();
        const hasRole: boolean = userProfile.roles?.some(
            (msalRole) => msalRole.toLocaleLowerCase() === roleToCheckLower,
        );

        return hasRole;
    }

    function isInRoleAny(rolesToCheck: Role[]): boolean {
        return rolesToCheck.some((role) => isInRole(role));
    }

    function isInRoleAll(rolesToCheck: Role[]): boolean {
        return rolesToCheck.every((role) => isInRole(role));
    }

    function getUserProfile(): IAuthProfile | null {
        if (isKioskAppPresent) {
            return {
                name: '',
                alias: '',
                email: '',
                roles: [],
                expiry: 0,
                tenantId: 'tid',
                oid: '',
            };
        }

        const oid = (getIdTokenClaim('oid') as string) ?? '';

        if (!account || !account.idTokenClaims) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            account
                ? logMessage({
                      method: 'GetUserProfile',
                      message: `Retrieved user profile without id token claims. Returning null.`,
                      severityLevel: SeverityLevel.Warning,
                  })
                : logMessage({
                      method: 'GetUserProfile',
                      message: `User profile is null. Returning null.`,
                      severityLevel: SeverityLevel.Warning,
                  });

            return null;
        }

        const alias = getAliasFromUsername(account.username);

        const roles = (getIdTokenClaim('roles') as string[]) ?? [];
        const exp = (getIdTokenClaim('exp') as number) ?? 0;

        return {
            name: account.name ?? '',
            alias: alias,
            email: account.username,
            roles: roles,
            expiry: exp + hoursToSeconds(Environment.aadConfig.tokenExpiryHours),
            tenantId: account.tenantId,
            oid: oid,
        };
    }

    function getAliasFromUsername(username: string): string {
        for (const domain of Environment.aadConfig.whitelistedDomains) {
            const pattern = new RegExp(domain);
            if (pattern.test(username)) {
                username = username.replace(pattern, '');
                break;
            }
        }

        return username;
    }

    function isTokenValid(): boolean {
        if (isKioskAppPresent) {
            return true;
        }
        const profile = getUserProfile();
        const currentTime = Math.round(new Date().getTime() / 1000);
        return (profile && profile.expiry > currentTime) || false;
    }

    function isTenantId(tenantId: string): boolean {
        const profile = getUserProfile();
        return profile?.tenantId === tenantId;
    }

    function isMicrosoftTenant(): boolean {
        return isTenantId(Environment.aadConfig.tenants.microsoft);
    }

    function isMsalInteractionInProgress(): boolean {
        return inProgress !== 'none';
    }

    function getLoginOriginalRequestUri(): string {
        let uri = window.location.href;
        if (uri === Environment.aadConfig.baseUri) {
            uri += 'home';
        }
        return uri;
    }

    function login(): void {
        if (!isTokenValid() && !isMsalInteractionInProgress()) {
            const request = {
                scopes: defaultScope,
            };

            logMessage({
                method: 'auth-context | login',
                message: `Login not currently in progress. Redirecting user to login page using scopes ${request.scopes.join(
                    ',',
                )}`,
                severityLevel: SeverityLevel.Information,
            });

            sessionStorage.setItem(
                BrowserCacheKeys.loginOriginalRequestUriKey,
                getLoginOriginalRequestUri(),
            );

            instance.loginRedirect(request);
        }
    }

    function logout(): void {
        logMessage({
            method: 'auth-context | logout',
            message: `Logging out oid ${getIdTokenClaim('oid') ?? ''}`,
            severityLevel: SeverityLevel.Information,
        });

        instance.logoutRedirect();
    }

    function getIdTokenClaim(
        key: string,
    ): string | number | string[] | object | undefined | unknown {
        if (account?.idTokenClaims && account.idTokenClaims[`${key}`]) {
            return account.idTokenClaims[`${key}`];
        }

        return undefined;
    }

    async function getToken(scope?: string[]): Promise<string | null> {
        const request = {
            account: account ?? undefined,
            scopes: scope ?? defaultScope,
        };
        const methodName = 'auth-context | getToken';

        if (isKioskAppPresent) {
            return getTokenFromWebView(request.scopes);
        }

        try {
            if (isMsalInteractionInProgress()) {
                logMessage({
                    method: methodName,
                    message: `Login currently in progress. Returning null token`,
                    severityLevel: SeverityLevel.Information,
                });
                return null;
            }

            if (account) {
                logMessage({
                    method: methodName,
                    message: `Requesting silent token for oid ${
                        getIdTokenClaim('oid') ?? ''
                    } scopes ${request.scopes.join(',')}`,
                    severityLevel: SeverityLevel.Information,
                });
                const result = await instance.acquireTokenSilent(request);
                logMessage({
                    method: methodName,
                    message: `Successfully retrieved silent token for oid ${
                        getIdTokenClaim('oid') ?? ''
                    } with scopes ${request.scopes.join(',')}`,
                    severityLevel: SeverityLevel.Information,
                });
                return result.accessToken;
            }
        } catch (error) {
            console.log('acquireTokenSilent failed, error: ', error);
            logMessage({
                method: methodName,
                message: `Failed to retrieve silent token for for oid ${
                    getIdTokenClaim('oid') ?? ''
                } with scopes ${request.scopes.join(',')} Error: ${error}`,
                severityLevel: SeverityLevel.Warning,
            });

            if (!isMsalInteractionInProgress()) {
                sessionStorage.setItem(
                    BrowserCacheKeys.loginOriginalRequestUriKey,
                    getLoginOriginalRequestUri(),
                );

                instance.acquireTokenRedirect(request);
            }

            return null;
        }
        return null;
    }

    /***
     * See Readme section `WebView2 communication between javascript and C#`
     */
    async function getTokenFromWebView(scopes: string[]): Promise<string | null> {
        if (!scopes || scopes.length === 0) {
            return null;
        }

        // get either key for Microsoft Graph AAD token or AAD Guid for Facilities AAD token
        let aadGuidOrKey = '';
        if (scopes[0].toLowerCase() === facilitiesKioskMicrosoftGraphTokenInfo.scope) {
            aadGuidOrKey = facilitiesKioskMicrosoftGraphTokenInfo.key;
        } else {
            const matches = webViewTokenGlobals.apiRegex.exec(scopes[0]);
            if (matches) {
                aadGuidOrKey = matches[1];
            }
        }

        if (aadGuidOrKey && isKioskAppPresent) {
            // When running in the kiosk app, the kiosk will intercept this URL and provide a response
            const response = await fetch(`https://127.0.0.1:7072/token?scp=${aadGuidOrKey}`);
            if (response.ok) {
                return response.text();
            }
        }
        return null;
    }

    function renderMode(): RenderMode {
        const pathname = window.location.pathname;
        if (kioskLandingPaths.findIndex((x) => pathname.includes(x)) >= 0) {
            if (config.renderModeDebug && !isKioskAppPresent) {
                return RenderMode.KioskLandingDebug;
            }
            return RenderMode.KioskLanding;
        }
        if (kioskPaths.findIndex((x) => pathname.includes(x)) >= 0) {
            if (config.renderModeDebug && !isKioskAppPresent) {
                return RenderMode.KioskDebug;
            }
            return RenderMode.Kiosk;
        }
        if (displayPaths.findIndex((x) => pathname.includes(x)) >= 0) {
            if (config.renderModeDebug && !isKioskAppPresent) {
                return RenderMode.DisplayDebug;
            }
            return RenderMode.Display;
        }

        return RenderMode.Web;
    }

    function isWebRenderMode(): boolean {
        const renderModeVar = renderMode();
        return webModeEquivalents.find((x) => x === renderModeVar) !== undefined;
    }

    function isKioskLandingRenderMode(): boolean {
        const renderModeVar = renderMode();
        return (
            renderModeVar === RenderMode.KioskLanding ||
            renderModeVar === RenderMode.KioskLandingDebug
        );
    }

    function isKioskRenderMode(): boolean {
        const renderModeVar = renderMode();
        return renderModeVar === RenderMode.Kiosk || renderModeVar === RenderMode.KioskDebug;
    }

    function isWebOrKioskRenderMode(): boolean {
        return isWebRenderMode() || isKioskRenderMode();
    }

    function isDisplayRenderMode(): boolean {
        const renderModeVar = renderMode();
        return renderModeVar === RenderMode.Display || renderModeVar === RenderMode.DisplayDebug;
    }

    return (
        <AuthContext.Provider
            value={{
                login: login,
                logout: logout,
                getUserProfile: getUserProfile,
                getToken: getToken,
                isMicrosoftTenant: isMicrosoftTenant,
                isInRole: isInRole,
                isInRoleAny: isInRoleAny,
                isInRoleAll: isInRoleAll,
                renderMode: renderMode,
                isWebRenderMode: isWebRenderMode,
                isKioskRenderMode: isKioskRenderMode,
                isKioskLandingRenderMode: isKioskLandingRenderMode,
                isWebOrKioskRenderMode: isWebOrKioskRenderMode,
                isDisplayRenderMode: isDisplayRenderMode,
            }}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;

export interface IAuthContext {
    login: () => void;
    logout: () => void;
    getUserProfile: () => IAuthProfile | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getToken: (scope?: any) => Promise<string | null>;
    isMicrosoftTenant: () => boolean;
    isInRole: (roleToCheck: Role) => boolean;
    isInRoleAny: (rolesToCheck: Role[]) => boolean;
    isInRoleAll: (rolesToCheck: Role[]) => boolean;
    renderMode: () => RenderMode;
    isWebRenderMode: () => boolean;
    isKioskRenderMode: () => boolean;
    isKioskLandingRenderMode: () => boolean;
    isWebOrKioskRenderMode: () => boolean;
    isDisplayRenderMode: () => boolean;
}

export interface IAuthProfile {
    name: string;
    alias: string;
    email: string;
    roles: string[];
    expiry: number;
    tenantId: string;
    oid: string;
}

export interface IAuthProviderConfigScopes {
    default: string[];
    graph?: string[];
}

export interface IAuthProviderTenants {
    microsoft: string;
    ame?: string;
}

export interface IAuthProviderConfig {
    clientID: string;
    authority: string;
    tenants: IAuthProviderTenants;
    whitelistedTenants: string[];
    whitelistedDomains: string[];
    redirectUri: string;
    postLogoutRedirectUri: string;
    cacheLocation: string;
    tokenExpiryHours: number;
    scopes: IAuthProviderConfigScopes;
}

export interface IAuthProviderProps {
    children: JSX.Element;
}
