import config from 'environments/environment';
import { GetAadHttpOptions, GetDefaultHttpOptions, JSON_CONTENT_TYPE } from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import { IPagedResults } from 'clients/http-options';

const { visitorServiceConfig } = config;

export default class VisitorClient {
    static async getVisitor(authContext: IAuthContext, id: string): Promise<IVisitorProfile> {
        const httpOptions = await GetAadHttpOptions(authContext, visitorServiceConfig.aadScopes);
        const url = visitorServiceConfig.baseUrl + visitorServiceConfig.visitorEndpoint + id;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else if (result.status === 404) {
            throw 'Visitor with the specified ID was not found';
        } else {
            throw result;
        }
    }

    static async createVisitor(
        authContext: IAuthContext,
        visitorRequest: IVisitorRequest,
    ): Promise<IVisitorProfile> {
        const httpOptions = await GetAadHttpOptions(authContext, visitorServiceConfig.aadScopes);
        httpOptions.body = JSON.stringify(visitorRequest);
        httpOptions.method = 'POST';
        const url = visitorServiceConfig.baseUrl + visitorServiceConfig.visitorEndpoint;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async updateVisitor(
        authContext: IAuthContext,
        id: string,
        visitorRequest: IVisitorRequest,
    ): Promise<IVisitorProfile> {
        const httpOptions = await GetAadHttpOptions(authContext, visitorServiceConfig.aadScopes);
        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify(visitorRequest);
        const url = visitorServiceConfig.baseUrl + visitorServiceConfig.visitorEndpoint + id;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async deleteVisitor(authContext: IAuthContext, id: string): Promise<IVisitorProfile> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'DELETE';
        const url = visitorServiceConfig.baseUrl + visitorServiceConfig.visitorEndpoint + id;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async getVisitorSearchResults(
        authContext: IAuthContext,
        request: ISearchVisitorRequest,
        continuationToken = '',
    ): Promise<IPagedResults<IVisitorProfile>> {
        const httpOptions = await GetAadHttpOptions(
            authContext,
            visitorServiceConfig.aadScopes,
            JSON_CONTENT_TYPE,
            continuationToken,
        );
        httpOptions.body = JSON.stringify(request);
        httpOptions.method = 'POST';
        const url = visitorServiceConfig.baseUrl + visitorServiceConfig.searchVisitorEndpoint;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }
}

export interface IVisitorProfile {
    id: string;
    firstName: string;
    middleName: string | null; // Null is found empirically. Same as the other null's below.
    lastName: string;
    email: string;
    company: string | null;
    title: string | null;
    sponsor: string;
    lastModifiedAtUTC: number | null;
    principalId?: string;
}

export interface IVisitorRequest {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    company: string;
    title: string;
    sponsor: string;
}

export interface ISearchVisitorRequest {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    company: string;
    title: string;
    sponsor: string;
}
