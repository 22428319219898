import { MessageBar, MessageBarType } from '@fluentui/react';
import DOMPurify from 'dompurify';
import Environment from 'environments/environment';
import React, { useEffect, useState } from 'react';
import { LocalStorageCache } from 'utils/local-storage-cache-utils';

const COMMS_MESSAGE_CACHE = 'customerCommunicationsMessageCache';
const COMMS_MESSAGE_KEY = 'customerCommunicationsMessage';
const DISMISS_KEY = 'messageBarDismissed';
const TTL = 3600000; // one hour in ms

// This is a message bar used for displaying general communications to the user.
// It is displayed at the top of all pages, under the navbar, and can be dismissed by the user. If dismissed, it will not be shown again for one hour OR until the message changes, whatever comes first.
// The message value is set by the GeneralConfig:CustomerCommunicationsMessageBar appsetting. When the value is set to null, nothing will display.

export default function GeneralCommunicationsMessageBanner(): JSX.Element {
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState<string | undefined>(undefined);

    const dismissMessageBar = (): void => {
        setMessage(undefined);
        setIsVisible(false);
        LocalStorageCache.store<boolean>(DISMISS_KEY, true, COMMS_MESSAGE_CACHE);
        LocalStorageCache.store(COMMS_MESSAGE_KEY, message, COMMS_MESSAGE_CACHE);
    };

    const messageBarType =
        MessageBarType[
            Environment.customerCommunicationsMessageBar.type as keyof typeof MessageBarType
        ] || MessageBarType.warning;

    useEffect(() => {
        const isDismissed = LocalStorageCache.retrieve<boolean>(
            DISMISS_KEY,
            COMMS_MESSAGE_CACHE,
            TTL,
        );
        const previousMessage = LocalStorageCache.retrieve(
            COMMS_MESSAGE_KEY,
            COMMS_MESSAGE_CACHE,
            Number.MAX_VALUE,
        );
        const currentMessage = Environment.customerCommunicationsMessageBar?.contentHtml || '';

        if (currentMessage !== '' && (!isDismissed || previousMessage !== currentMessage)) {
            setMessage(currentMessage);
            setIsVisible(true);
        }
    }, []);

    return (
        <>
            {isVisible && message && (
                <MessageBar
                    onDismiss={dismissMessageBar}
                    messageBarType={messageBarType}
                    dismissButtonAriaLabel='Close'>
                    <span
                        dangerouslySetInnerHTML={{
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            __html: DOMPurify.sanitize(message as string),
                        }}
                    />
                </MessageBar>
            )}
        </>
    );
}
