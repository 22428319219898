import { IPortalConfiguration } from 'clients/config';
import { IAuthProviderConfig } from 'contexts/auth-context';

const Environment = {
    featureFlags: {
        'emails/core': false,
        'emails/logSanitized': false,
        'eligibility/enableAutoAssign': false,
        'activity/core': false,
        'document/storage': false,
        'groups/core': false,
        'groups/metadata': false,
        'groups/linkToSecurityGroup': false,
        'groups/POC': false,
        'profile/visitor': false,
        'profile/usGov': false,
        'profile/attributesUpdatedMessageBar': false,
        'screening/core': false,
        'screening/admin': false,
        'screening/contractOwners': false,
        'screening/searchFilterContentRedesign': false,
        'screening/agencyDropdown': false,
        'facilities/core': false,
        'forms/core': false,
        'forms/edit': false,
        'sca/core': false,
        'staffing/core': false,
        'readiness/core': false,
        'support/linkToAka': false,
        'groups/min2FteOwners': false,
        'groups/linkOnPremAD': false,
        'groups/linkWithServicePrincipal': false,
        'core/headerBanner': false,
        'core/attributes': false,
        'suitability/records': false,
        'suitability/suitabilityRecordsTab': false,
        'cloudScreening/hireRightApi': false,
        'groups/enableDynamicGroup': false,
        'employee/core': false,
        'screening/cloudReadinessReportDownload': false,
        'forms/useFluentUITimePicker': false,
        'groups/enableNonExternalComponents': false,
        'about/user': false,
        'csat': false,
        'eligibility/enableEligibilities': false,
        'guests/core': false,
    },
    production: false,
    environmentName: '',
    orgExplorerUrl: '',
    defaultDomain: '',
    supportEmail: '',
    supportPhone: '',
    aadConfig: {
        clientID: '',
        authority: 'https://login.microsoftonline.com/common/',
        tenants: {
            microsoft: '72f988bf-86f1-41af-91ab-2d7cd011db47', // REDUNDANT - Not included in App Config
            ame: '33e01921-4d64-4f8c-a055-5bdaffd5e33d', // REDUNDANT - Not included in App Config
        },
        whitelistedTenants: [
            '72f988bf-86f1-41af-91ab-2d7cd011db47',
            '33e01921-4d64-4f8c-a055-5bdaffd5e33d',
        ],
        whitelistedDomains: ['@(.*?.|)microsoft.com', '@ame.gbl'],
        redirectUri: '',
        postLogoutRedirectUri: '',
        baseUri: '',
        cacheLocation: 'localStorage',
        tokenExpiryHours: 7,
        scopes: {
            graph: ['user.read.all'],
            default: [''],
        },
        cloudDiscoveryMetadata: null as null | string,
    },
    employeeServiceConfig: {
        baseUrl: '',
        aadScopes: [''],
        employeeEndpoint: 'v1/eds/employee/',
        employeeEditableRecordEndpoint: 'v1/eds/employee/{idOrAliasOrGuid}/editableRecord',
        multiEmployeeEditableRecordLookUpsEndpoint: 'v1/eds/employee/editableRecords',
        upsertEmployeeEditableRecordEndpoint: 'v1/eds/employee/editableRecord',
        convertEditableRecordEndpoint: 'v1/eds/employee/convert/editableRecord',
        recordsEndpoint: 'v1/eds/employee/records',
        searchRecordsEndpoint: 'v1/eds/search/employee/records/',
        positionsEndpoint: 'v1/eds/employee-positions/',
        employeeHierarchyEndpoint: '/hierarchy',
        prehireReportEndpoint: 'v1/eds/employee/prehireReport',
        employeeMetadata: 'v1/eds/metadata',
        employeeBadgeSerialNumberEndpoint: 'v1/eds/employee/badgeSerialNumber/',
    },
    scaServiceConfig: {
        baseUrl: '',
        aadScopes: [''],
        scaManagerDirectsEndpoint: 'v1/sca/employees/managers/{id}/directs/review',
        scaManagerReviewsEndpoint: 'v1/sca/employees/managers/{id}/review/{reviewId}',
        scaEmployeeEndpoint: 'v1/sca/employees/',
        scaEmployeeReviewEndpoint: 'v1/sca/employees/{id}/reviews',
        scaEmployeeReviewInPeriod: 'v1/sca/employees/{id}/review/{reviewPeriodId}',
        scaEmployeeTopSortedReview: 'v1/sca/employees/{id}/sortedReviews',
        scaEmployeeCurrentReviewEndpoint: 'v1/sca/employees/{id}/current/review',
        scaEmployeeRecordsForExec: 'v1/sca/employees/exec/{personnelId}/review/{reviewPeriodId}',
        reviewPeriodEndpoint: 'v1/sca/review/periods/',
        reportReviewEndpoint: 'v1/sca/report/review/',
    },
    formsServiceConfig: {
        baseUrl: '',
        aadScopes: [''],
    },
    groupServiceConfig: {
        baseUrl: '',
        aadScopes: [''],
        serviceIdentityAppId: '',
        myMembershipsEndpoint: 'v1/grp/group/memberships/me',
        myGroupsEndpoint: 'v1/grp/group/memberships/mygroups',
        groupEndpoint: 'v1/grp/group/',
        groupsEndpoint: 'v1/grp/groups/',
        membershipsEndpoint: 'v1/grp/group/memberships/',
        rulesEndpoint: 'v1/grp/group/rules/',
        reportsEndpoint: 'v1/grp/reporting/groups/',
        securityGroupsEndpoint: 'v1/grp/group/securitygroup/',
        violationsEndpoint: 'v1/grp/policy/violations/groups/', // Groups service: GroupPolicyViolationsControllers
        courseDetailEndpoint: 'v1/grp/course/details',
        isFteEndpoint: 'v1/grp/group/isfte',
        coreIdentityManagementAppId: '75976fa5-32db-4046-87dc-ebc1a20343e5',
        principalRecordAttributeIdMap: {
            fteId: '',
        },
        featureFlags: {
            policyCheckModal: false,
            allowApproveUnsigned: false,
        },
    },
    emailServiceConfig: {
        baseUrl: '',
        aadScopes: [''],
        emailEndpoint: 'v1/eml/email/',
        emailSearchEndpoint: 'v1/eml/search/email/records/',
        emailTemplateEndpoint: 'v1/eml/templates/',
        emailPauseStatusEndpoint: 'v1/eml/email/pause/status',
        emailPauseEndpoint: 'v1/eml/email/pause',
        emailResumeEndpoint: 'v1/eml/email/resume',
        emailResendEndpoint: 'v1/eml/email/resend/',
        emailServicesEndpoint: 'v1/eml/services',

        templateSuffix: 'template',
    },
    emailAddress: {
        dev: '',
        fso: '',
        personnel: '',
        readiness: '',
        groupSync: '',
        fednetSync: '',
    },
    documentsServiceConfig: {
        baseUrl: '',
        aadScopes: [''],
        documentRecord: 'v1/documents',
        documentRecordById: 'v1/documents/{id}',
        downloadDocument: 'v1/documents/{id}/download',
        sendDocumentNotification: 'v1/documents/sendDocumentNotification',
        shareDocument: 'v1/documents/{id}/share',
        usGovDocumentToken: 'v1/screenings/{id}/token/documents',
        publicTrustDocumentToken: 'v1/PublicTrustScreening/{id}/token/documents',
        unlock: 'v1/documents/{documentId}/unlock',
        lock: 'v1/documents/{documentId}/lock',
        documentsByAssociatedUser: 'v1/documents/associated/{userId}',
        updateTTLForAssociatedUser: 'v1/documents/associated/{documentId}/ttl/{ttlOffSeconds}',
        associatedUserDocumentTTL: 5184000, // 60 days in seconds
    },
    nominationServiceConfig: {
        baseUrl: '',
        screeningsEndpoint: 'v1/Screenings',
        screeningsResultCount: 15000,
        publicTrustEndpoint: 'v1/PublicTrustScreening',
        contractsEndpoint: 'v1/Contracts',
        agenciesEndpoint: 'v1/Agencies',
        publicTrustContractsEndpoint: 'v1/PublicTrustContracts',
        customerReportEndpoint: 'v1/screenings/CustomerReport',
        cloudReadinessReportEndpoint: 'v1/screenings/readinessReport',
        document: {
            baseUrl: '',
            requestableDocumentsToRequest: 'v1/screenings/document/requestables/for/',
            createRequestableDocument: 'v1/screenings/document/requestable/',
            screeningDocumentRequestRecordChange: 'v1/screenings/document/request/records/changes',
            documentRequestedForScreeningId: 'v1/screenings/document/request/records/',
            documentMetadataFromDocumentIds: 'v1/screenings/document/metadata/documentIds',
            requestDocument: 'v1/screenings/document/requestable/document/{id}',
            associatedUserToken: 'v1/screenings/document/associatedUser/{userId}/token',
            publicTrustRequestDocumentEndPoint: 'v1/publictrustscreening/DocumentRequest',
            documentTypeItems: 'v1/screenings/Document/documentTypeItems',
        },
    },
    eligibilityServiceConfig: {
        baseUrl: '',
        aadScopes: [''],
        attributesEndpoint: 'v1/egs/attributes/',
        eligibilitiesEndpoint: 'v1/egs/eligibilities/',
        eligibilityRequestEndpoint: 'v1/egs/eligibilities/{id}/requests/{requestId}/',
        eligibilityAutditLogEndpoint: 'v1/egs/eligibilities/{id}/logs',
        eligibilityRequestAutditLogEndpoint: 'v1/egs/eligibilities/{id}/requests/logs',
        personnelAttributesEndpoint: 'v1/egs/personnel/{id}/attributes',
        personnelEligibilitiesEndpoint: 'v1/egs/personnel/{id}/eligibilities',
        personnelAvailableEligibilitiesEndpoint: 'v1/egs/personnel/{id}/available/eligibilities',
        personnelAssignmentEndpoint: 'v1/egs/personnel/',
    },
    graphServiceConfig: {
        baseUrl: 'https://graph.microsoft.com/v1.0',
        batchEndpoint: '/$batch',
        usersEndpoint: '/users/',
        photoEndpoint: '/photos/48x48/$value',
        meEndpoint: '/me/',
        groupsEndpoint: '/groups/',
        groupMembersEndpoint: '/groups/{id}/members',
    },
    activityServiceConfig: {
        baseUrl: '',
        aadScopes: [''],
        activityEndpoint: 'v1/activities',
        countEndpoint: '/count',
        queryEndpoint: '/query',
        screeningActivityToken: {
            baseUrl: '',
            tokenEndpoint: 'v1/screenings/{screeningId}/token/activities',
        },
        profileActivityToken: {
            baseUrl: '',
            tokenEndpoint: 'v1/screenings/profile/{profileId}/token/activities',
        },
        documentActivityToken: {
            baseUrl: '',
            tokenEndpoint: 'v1/documents/{documentId}/token/activities',
        },
    },
    applicationInsightsConnectionString: '',
    processOwnerGroupId: '',
    privilegedGroupId: '',
    staffingServiceConfig: {
        baseUrl: `${''}`,
        aadScopes: [] as string[],
        organizationEndpoint: 'v1/staffing/organization/',
        teamSuffix: '/team/',
        projectEndpoint: 'v1/staffing/project/',
        allocationPrefix: 'v1/staffing/allocation',
        allocationEndpoint: 'v1/staffing/allocation/',
        cloudEndpoint: 'v1/staffing/cloud/',
        userAuthorizationEndpoint: 'v1/staffing/authorization/user/',
        organizationSuffix: '/organization/',
        permissionSuffix: '/role/',
        clearancePrefix: '/clearance',
        azureServicesEndpoint: 'v1/staffing/AzureService/services',
        teamAzureServiceEndpoint: 'v1/staffing/AzureService/teamServices',
        azureServiceKpisEndpoint: 'v1/staffing/AzureService/kpis',
        accessibleOrganizationsEndPoint: 'v1/staffing/authorization/canaccess',
        organizationAccessDetailEndpoint: 'v1/staffing/authorization/orgaccessdetail',
    },
    cloudScreeningServiceConfig: {
        baseUrl: '',
        aadScopes: [] as string[],
        backgroundCheckEndpoint: 'v2/screenings/cloud-screening/backgroundCheck',
        screeningEndpoint: 'v2/screenings/cloud-screening',
        myScreeningsEndpoint: 'v2/screenings/cloud-screening/my-screenings',
        reportScreenings: 'v2/screenings/cloud-screening/manager/{managerId}',
        requestedRegionalScreeningsEndpoint: 'v2/screenings/cloud-screening/requests/{region}',
        monthlyReport: 'v2/screenings/cloud-screening/monthly-report',
        userTypesEndpoint: 'v2/screenings/cloud-screening/user-types',
        addDelegateAssignment:
            'v2/screenings/cloud-screening/delegate/{delegateId}/manager/{managerId}/delegate-assignments',
        deleteDelegateAssignment:
            'v2/screenings/cloud-screening/delegate/{delegateId}/manager/{managerId}/delegate-assignments',
        assignmentsByManagerId:
            'v2/screenings/cloud-screening/manager/{personnelId}/delegate-assignments',
        assignmentsByDelegateId:
            'v2/screenings/cloud-screening/delegate/{personnelId}/delegate-assignments',
        directReports: 'v2/screenings/cloud-screening/manager/{id}/reports',
        allReports: 'v2/screenings/cloud-screening/manager/{id}/reports/all',
        scopesForManager: 'v2/screenings/cloud-screening/scopes/{managerId}',
        managerReminder: 'v2/screenings/cloud-screening/manager-reminder/{alias}',
        allowLists: 'v2/screenings/cloud-screening/scopes/allowList',
        deleteAllowList: 'v2/screenings/cloud-screening/scopes/allowList/{managerId}',
    },
    clearanceServiceConfig: {
        baseUrl: `${''}`,
        clearancesSearchByIdsEndpoint: 'v1/clearance/getClearancesByIds',
        clearancesEndpoint: 'v1/clearance/employee/{id}',
        clearancesUpsertEndpoint: 'v1/clearance',
        clearancesDeleteEndpoint: 'v1/clearance/{id}/delete',
        clearanceDownloadReportEndpoint: 'v1/clearance/report/employee/{id}',
        clearanceRecordsExcelDataEndpoint: 'v1/clearance/clearancerecordexceldata',
        clearanceHolderExcelDataEndpoint: 'v1/clearance/clearanceholderexceldata',
        employeeIdsWithFutureTerminationDates: 'v1/clearance/employeeIdsWithFutureTerminationDates',
        polygraphEndpoint: 'v1/clearance/polygraph/employee/{id}',
        polygraphUpsertEndpoint: 'v1/clearance/polygraph',
        polygraphDeleteEndpoint: 'v1/clearance/polygraph/{id}/delete',
        specialAccessEndpoint: 'v1/clearance/specialaccess/employee/{id}',
        specialAccessUpsertEndpoint: 'v1/clearance/specialaccess',
        specialAccessesDeleteEndpoint: 'v1/clearance/specialaccess/delete',
        statusesEndpoint: 'v1/clearance/statuses',
        clearanceUploadEndpoint: 'v1/clearance/upload',
    },
    suitabilityServiceConfig: {
        baseUrl: `${''}`,
        suitabilitiesEndpoint: 'v1/suitability/employee/{id}',
        suitabilitiesUpsertEndpoint: 'v1/suitability',
        suitabilitiesDeleteEndpoint: 'v1/suitability/{id}/delete',
        suitabilitiesSeedEndpoint: 'v1/suitability/seed',
        suitabilitiesDownloadReportEndpoint: 'v1/suitability/report/employee/{id}',
        statusesEndpoint: 'v1/suitability/statuses',
        suitabilityRecordsExcelDataEndpoint: 'v1/suitability/suitabilityReportExcelData',
        suitabilityHolderExcelDataEndpoint: 'v1/suitability/suitabilityHoldersExcelData',
        suitabilityUploadEndpoint: 'v1/suitability/upload',
    },
    personnelProfile: {
        eligibilityFlag: false,
        prehireFlag: false,
        isUsCitizenTestingFlag: false,
    },
    visitorServiceConfig: {
        baseUrl: `${''}`,
        aadScopes: [''],
        visitorEndpoint: 'v1/vds/visitor/',
        searchVisitorEndpoint: 'v1/vds/search/visitor/records',
    },
    facilitiesServiceConfig: {
        baseUrl: `${''}`,
        tokenEndpoint: 'v1/facilities/token',
        activityTokenEndpoint: 'v1/facilities/activity-token',
        equipmentEndpoint: 'v1/facilities/equipment',
        equipmentUploadEndpoint: 'v1/facilities/equipment/upload',
        searchEquipmentEndpoint: 'v1/facilities/equipment/search',
        downloadEquipmentReportEndppint: 'v1/facilities/equipment/report',
        aadScopes: [''],
        management: {
            extras: {
                enabled: false,
            },
        },
    },
    readinessServiceConfig: {
        baseUrl: `${''}`,
        aadScopes: [''],
        criterionEndpoint: 'v1/readiness/criterion/',
        scopesEndpoint: 'v1/readiness/scope/',
        statusEndpoint: 'v1/readiness/status/',
    },
    coreApiConfig: {
        baseUrl: '',
        aadScopes: [''],
    },
    renderModeDebug: 'FALSE',
    privacyLinkText: '',
    privacyLinkUrl: '',
    customerCommunicationsMessageBar: {
        type: 'warning',
        contentHtml: '',
    },
};

// Overrides configuration with user session override provided in either sessionStorage or query parameter
function userConfigOverride(
    configObject: object,
    searchParams: URLSearchParams,
    prefix?: string,
): void {
    for (const property in configObject) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const value: unknown = (configObject as any)[property];

        const propertyFullName = (prefix !== undefined ? `${prefix}.` : '') + property;

        if (value !== null && value !== undefined) {
            if (typeof value === 'object') {
                userConfigOverride(value, searchParams, propertyFullName);
            } else if (typeof value === 'boolean') {
                // Restrict setting via query string to featureFlag configs to prevent click jacking
                const queryValue = propertyFullName.startsWith('featureFlag')
                    ? searchParams.get(propertyFullName)
                    : null;

                let overrideValue = queryValue ?? sessionStorage.getItem(propertyFullName);

                if (overrideValue !== null) {
                    overrideValue = overrideValue.toLowerCase();
                }

                if (overrideValue === 'true' || overrideValue === 'false') {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (configObject as any)[property] = overrideValue === 'true';

                    sessionStorage.setItem(propertyFullName, overrideValue);
                }
            } else if (typeof value === 'string') {
                const queryValue = searchParams.get(propertyFullName);
                let overrideValue = queryValue ?? sessionStorage.getItem(propertyFullName);

                if (overrideValue !== null) {
                    overrideValue = overrideValue.toLowerCase();

                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (configObject as any)[property] = overrideValue;
                    sessionStorage.setItem(propertyFullName, overrideValue);
                }
            }
        }
    }
}

export const overrideEnvironmentConfig = (
    config: IPortalConfiguration | undefined,
): { aadConfig: IAuthProviderConfig | null } => {
    if (!config) {
        return { aadConfig: null };
    }

    userConfigOverride(config, new URLSearchParams(window.location.search));

    const {
        apiConfig,
        generalConfig,
        aadConfig,
        featureFlag,
        emailConfig,
        attributeIdMappingConfig,
    } = config;

    Environment.featureFlags = {
        'emails/core': featureFlag.coreEmailService,
        'emails/logSanitized': featureFlag.emailLogSanitized,
        'eligibility/enableAutoAssign': featureFlag.newEligibilityEnableAutoOptions,
        'eligibility/enableEligibilities': featureFlag.enableEligibilities,
        'activity/core': featureFlag.coreActivityService,
        'document/storage': featureFlag.documentStorage,
        'groups/core': featureFlag.coreGroupService,
        'groups/metadata': featureFlag.enableGroupMetaDataField,
        'groups/linkToSecurityGroup': featureFlag.enableGroupLinkSecurityGroup,
        'groups/POC': featureFlag.enableGroupPOC,
        'profile/visitor': featureFlag.enableProfileVisitor,
        'profile/usGov': featureFlag.enableProfileUSGov,
        'profile/attributesUpdatedMessageBar': featureFlag.enableProfileAttributesUpdatedMessageBar,
        'screening/core': featureFlag.coreScreeningService,
        'screening/admin': featureFlag.enableScreeningContracts,
        'screening/contractOwners': featureFlag.enableScreeningContractOwners,
        'screening/searchFilterContentRedesign':
            featureFlag.enableScreeningSearchFilterContentRedesign,
        'screening/agencyDropdown': featureFlag.enableScreeningAgencyDropdown,
        'facilities/core': featureFlag.coreFacilitiesService,
        'forms/core': featureFlag.coreFormsService,
        'sca/core': featureFlag.coreSCAService,
        'staffing/core': featureFlag.coreStaffingService,
        'readiness/core': featureFlag.coreReadinessService,
        'support/linkToAka': featureFlag.supportLinkToAKA,
        'groups/min2FteOwners': featureFlag.enableGroupMin2FteOwners,
        'groups/linkOnPremAD': featureFlag.enableLinkOnPremAD,
        'groups/linkWithServicePrincipal': featureFlag.enableLinkWithServicePrincipal,
        'core/headerBanner': featureFlag.enableHeaderBanner,
        'core/attributes': featureFlag.enablePersonnelCoreAttributes,
        'suitability/records': featureFlag.enableSuitabilityRecordsTable,
        'suitability/suitabilityRecordsTab': featureFlag.enableTabToSuitabilityRecordsTable,
        'forms/edit': featureFlag.enableFormsEditingFeature,
        'cloudScreening/hireRightApi': featureFlag.enableCloudScreeningHireRightApi,
        'groups/enableDynamicGroup': featureFlag.enableDynamicGroup,
        'employee/core': featureFlag.enableEmployeeService,
        'screening/cloudReadinessReportDownload': featureFlag.enableCloudReadinessReportDownload,
        'forms/useFluentUITimePicker': featureFlag.enableFluentUITimePickerInForms,
        'groups/enableNonExternalComponents': featureFlag.enableNonExternalComponents,
        'about/user': featureFlag.enableAboutUser,
        'csat': featureFlag.enableCSATFeedback,
        'guests/core': featureFlag.enableCoreGuests,
    };

    Environment.production = generalConfig.productionFlag;
    Environment.environmentName = generalConfig.environmentName;
    Environment.orgExplorerUrl = generalConfig.orgExplorerUrl;
    Environment.defaultDomain = generalConfig.defaultDomain;
    Environment.supportEmail = generalConfig.supportEmail;
    Environment.supportPhone = generalConfig.supportPhone;
    Environment.customerCommunicationsMessageBar.type =
        generalConfig.customerCommunicationsMessageBar.type;
    Environment.customerCommunicationsMessageBar.contentHtml =
        generalConfig.customerCommunicationsMessageBar.contentHtml;
    Environment.aadConfig.clientID = aadConfig.clientId || '';
    Environment.aadConfig.redirectUri = aadConfig.redirectUri || '';
    Environment.aadConfig.postLogoutRedirectUri = aadConfig.logoutRedirectUri || '';
    Environment.aadConfig.baseUri = aadConfig.baseUri || '';
    Environment.aadConfig.scopes.default = [aadConfig.aadScope || ''];
    Environment.aadConfig.authority = aadConfig.authority;
    Environment.aadConfig.whitelistedTenants = aadConfig.whitelistedTenants;
    Environment.aadConfig.whitelistedDomains = aadConfig.whitelistedDomains;
    Environment.aadConfig.cloudDiscoveryMetadata = aadConfig.cloudDiscoveryMetadata;

    Environment.graphServiceConfig.baseUrl = aadConfig.graphServiceBaseUrl;

    Environment.employeeServiceConfig.baseUrl = apiConfig.employeeApiBaseUrl || '';
    Environment.employeeServiceConfig.aadScopes = [apiConfig.defaultApiAadScope || ''];

    Environment.scaServiceConfig.baseUrl = apiConfig.scaApiBaseUrl || '';
    Environment.scaServiceConfig.aadScopes = [apiConfig.defaultApiAadScope || ''];

    Environment.formsServiceConfig.baseUrl = apiConfig.formApiBaseUrl || '';
    Environment.formsServiceConfig.aadScopes = [apiConfig.defaultApiAadScope || ''];

    Environment.groupServiceConfig.baseUrl = apiConfig.groupApiBaseUrl || '';
    Environment.groupServiceConfig.aadScopes = [apiConfig.defaultApiAadScope || ''];
    Environment.groupServiceConfig.featureFlags = {
        policyCheckModal: generalConfig.policyCheckModalFlag,
        allowApproveUnsigned: generalConfig.allowApproveUnsignedUAR,
    };
    Environment.groupServiceConfig.principalRecordAttributeIdMap = {
        fteId: attributeIdMappingConfig?.fteId ?? '',
    };
    Environment.groupServiceConfig.serviceIdentityAppId = apiConfig.groupsServiceIdentityAppId;
    Environment.emailServiceConfig.baseUrl = apiConfig.emailApiBaseUrl || '';
    Environment.emailServiceConfig.aadScopes = [apiConfig.defaultApiAadScope || ''];

    Environment.documentsServiceConfig.baseUrl = apiConfig.documentApiBaseUrl || '';
    Environment.documentsServiceConfig.aadScopes = [apiConfig.documentApiAadScope || ''];

    Environment.nominationServiceConfig.baseUrl = apiConfig.nominationApiBaseUrl || '';
    Environment.nominationServiceConfig.document.baseUrl = apiConfig.nominationApiBaseUrl || '';

    Environment.eligibilityServiceConfig.baseUrl = apiConfig.eligibilityApiBaseUrl || '';
    Environment.eligibilityServiceConfig.aadScopes = [apiConfig.defaultApiAadScope || ''];

    Environment.activityServiceConfig.baseUrl = apiConfig.activityServiceBaseUrl || '';
    Environment.activityServiceConfig.aadScopes = [apiConfig.activityServiceAadScope || ''];
    Environment.activityServiceConfig.screeningActivityToken.baseUrl =
        apiConfig.nominationApiBaseUrl || '';
    Environment.activityServiceConfig.profileActivityToken.baseUrl =
        apiConfig.nominationApiBaseUrl || '';
    Environment.activityServiceConfig.documentActivityToken.baseUrl =
        apiConfig.documentApiBaseUrl || '';

    Environment.staffingServiceConfig.baseUrl = `${apiConfig.staffingApiBaseUrl || ''}`;
    Environment.staffingServiceConfig.aadScopes = [apiConfig.defaultApiAadScope];

    Environment.cloudScreeningServiceConfig.baseUrl = apiConfig.screeningApiBaseUrl || '';
    Environment.cloudScreeningServiceConfig.aadScopes = [apiConfig.screeningApiAadScope];

    Environment.clearanceServiceConfig.baseUrl = `${apiConfig.clearanceApiBaseUrl || ''}`;

    Environment.suitabilityServiceConfig.baseUrl = `${apiConfig.suitabilityApiBaseUrl || ''}`;

    Environment.visitorServiceConfig.baseUrl = `${apiConfig.visitorApiBaseUrl || ''}`;
    Environment.visitorServiceConfig.aadScopes = [apiConfig.defaultApiAadScope || ''];

    Environment.facilitiesServiceConfig.baseUrl = `${apiConfig.facilityApiBaseUrl || ''}`;
    Environment.facilitiesServiceConfig.aadScopes = [apiConfig.facilityApiAadScope || ''];
    Environment.facilitiesServiceConfig.management = {
        extras: {
            enabled: generalConfig.facilityApiExtraFlag,
        },
    };

    Environment.readinessServiceConfig.baseUrl = `${apiConfig.readinessApiBaseUrl || ''}`;
    Environment.readinessServiceConfig.aadScopes = [apiConfig.readinessApiAadScope || ''];

    Environment.coreApiConfig.baseUrl = apiConfig.coreApiBaseUrl || '';
    Environment.coreApiConfig.aadScopes = [apiConfig.coreApiAadScope || ''];

    Environment.applicationInsightsConnectionString =
        generalConfig.appInsightsConnectionString || '';
    Environment.processOwnerGroupId = generalConfig.processOwnerGroupId || '';

    Environment.privilegedGroupId = generalConfig.privilegedGroupId || '';
    Environment.personnelProfile = {
        eligibilityFlag: generalConfig.eligibilityApiProfileFlag,
        prehireFlag: generalConfig.preHireFlag,
        isUsCitizenTestingFlag: generalConfig.isVerifiedUSCitizenFlag,
    };

    Environment.emailAddress.dev = emailConfig.dev;
    Environment.emailAddress.fso = emailConfig.fso;
    Environment.emailAddress.personnel = emailConfig.personnel;
    Environment.emailAddress.readiness = emailConfig.readiness;
    Environment.emailAddress.fednetSync = emailConfig.fednetSync;
    Environment.emailAddress.groupSync = emailConfig.groupSync;

    Environment.renderModeDebug = generalConfig.renderModeDebug || 'FALSE';

    Environment.privacyLinkText = generalConfig.privacyLinkText;
    Environment.privacyLinkUrl = generalConfig.privacyLinkUrl;
    return Environment;
};

export default Environment;
