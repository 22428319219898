import React, { useState } from 'react';
import { IVisitorProfile } from 'clients/visitor-client';
import { mergeStyleSets, Stack } from '@fluentui/react';
import { IEmployee } from 'clients/employee-client';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import VisitorProfileModalActionButton, {
    VisitorProfileModeEnum,
} from 'components/visitors/visitor-profile-modal-action-button';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';

interface IDisplayVisotorInfoProps {
    visitor: IVisitorProfile | undefined;
    sponsor: IEmployee | undefined;
    showEditVisitorButton: boolean;
}

export default function DisplayVisitorInfo(props: IDisplayVisotorInfoProps): JSX.Element {
    const [visitorInfo, setvisitorInfo] = useState(props.visitor);
    const useCoreGuests = useFeatureFlag(FeatureFlagKeys.guestsCore);
    const displayItem = (label: string, value: string | number | undefined): JSX.Element => {
        let contentValue;

        if (label === 'Email') {
            contentValue = <a href={`mailto:${value}`}>{value}</a>;
        } else if (label === 'Sponsor') {
            contentValue = useCoreGuests ? (
                <CoreEmployeeHoverCardFromPrincipalId principalId={value?.toString()} />
            ) : (
                <EmployeeBasicHoverCard personnelId={value?.toString()} />
            );
        } else {
            contentValue = value;
        }

        return (
            <Stack horizontal>
                <Stack.Item className={styles.label}>
                    <strong>{label}</strong>
                </Stack.Item>
                <Stack.Item className={styles.value}>{contentValue}</Stack.Item>
            </Stack>
        );
    };

    if (!props.visitor) return <></>;

    return (
        <>
            {props.showEditVisitorButton && (
                <VisitorProfileModalActionButton
                    mode={VisitorProfileModeEnum.update}
                    visitor={props.visitor}
                    overrideButtonText='Edit Visitor'
                    onVisitorUpdated={(updatedVisitor) => setvisitorInfo(updatedVisitor)}
                />
            )}
            {displayItem('First Name', visitorInfo?.firstName)}
            {displayItem('Middle Name', visitorInfo?.middleName ?? '')}
            {displayItem('Last Name', visitorInfo?.lastName)}
            {displayItem('Email', visitorInfo?.email)}
            {displayItem('Visitor Id', visitorInfo?.id)}
            {displayItem('Company', visitorInfo?.company ?? '')}
            {displayItem('Title', visitorInfo?.title ?? '')}
            {displayItem('Sponsor', visitorInfo?.sponsor)}
        </>
    );
}

const styles = mergeStyleSets({
    label: {
        width: 150,
        padding: 7,
    },
    value: {
        width: 200,
        padding: 7,
    },
});
