import { IAuthContext } from 'contexts/auth-context';
import config from 'environments/environment';
import {
    AssignmentsClient,
    AttributesClient,
    AttributeSetsClient,
    GuestsClient,
    PrincipalGetByIdsRequest,
    PrincipalGetByIdsResult,
    PrincipalsClient,
    PrincipalSourceDataFieldsClient,
} from 'personnel-core-clients';

export class CorePrincipalsClient extends PrincipalsClient {
    constructor(authContext: IAuthContext) {
        super(
            { tokenService: authContext, scopes: config.coreApiConfig.aadScopes },
            config.coreApiConfig.baseUrl,
        );
    }
}

export class CoreAttributesClient extends AttributesClient {
    constructor(authContext: IAuthContext) {
        super(
            { tokenService: authContext, scopes: config.coreApiConfig.aadScopes },
            config.coreApiConfig.baseUrl,
        );
    }
}

export class CoreAttributeSetsClient extends AttributeSetsClient {
    constructor(authContext: IAuthContext) {
        super(
            { tokenService: authContext, scopes: config.coreApiConfig.aadScopes },
            config.coreApiConfig.baseUrl,
        );
    }
}

export class CoreAssignmentsClient extends AssignmentsClient {
    constructor(authContext: IAuthContext) {
        super(
            { tokenService: authContext, scopes: config.coreApiConfig.aadScopes },
            config.coreApiConfig.baseUrl,
        );
    }
}

export class CorePrincipalSourceDataFieldsClient extends PrincipalSourceDataFieldsClient {
    constructor(authContext: IAuthContext) {
        super(
            { tokenService: authContext, scopes: config.coreApiConfig.aadScopes },
            config.coreApiConfig.baseUrl,
        );
    }
}

export class CoreGuestsClient extends GuestsClient {
    constructor(authContext: IAuthContext) {
        super(
            { tokenService: authContext, scopes: config.coreApiConfig.aadScopes },
            config.coreApiConfig.baseUrl,
        );
    }
}

export interface ObtainPrincipalRecordsProps {
    principalClient: CorePrincipalsClient;
    principalIds: string[];
    attributeIds?: string[] | undefined;
    chunkSize?: number | undefined;
}

/**
 * Call principalClient GetByIds with list of principal ids and optional attribute ids.
 * @param principalIds array of principalIds to get
 * @param principalClient PrincipalClient to utilize calls with
 * @returns array of IPrincipalRecords resolved in the call.
 *
 * TODO: This is a temporary solution to the problem of getting principal records,
 *   hopefully we can find a better solution, and optimized here once that happens.
 */
export async function obtainPrincipalRecords({
    principalClient,
    principalIds,
    attributeIds,
    chunkSize = 500,
}: ObtainPrincipalRecordsProps): Promise<PrincipalGetByIdsResult[]> {
    const uniquePrincipalIds = principalIds.filter((c, index) => {
        return principalIds.indexOf(c) === index;
    });

    const returnList: PrincipalGetByIdsResult[] = [];
    const promises: Promise<PrincipalGetByIdsResult[]>[] = [];
    for (let i = 0; i < uniquePrincipalIds.length; i += chunkSize) {
        const principalChunk = uniquePrincipalIds.slice(i, i + chunkSize);
        const request = new PrincipalGetByIdsRequest();
        request.principalIds = principalChunk;
        request.attributeIds = attributeIds;
        promises.push(principalClient.getByIds(request));
    }

    await Promise.allSettled(promises).then((results) => {
        const successfulPromises = results
            .filter((result) => result.status === 'fulfilled')
            .map((result) => (result as PromiseFulfilledResult<PrincipalGetByIdsResult[]>).value);

        returnList.push(...successfulPromises.reduce((acc, array) => acc.concat(array), []));
    });

    return returnList;
}
